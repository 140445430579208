import { LogoutDiv, MenuContainer, MenuListDiv, SettingDiv } from "../style/Global.styled";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import { faHouse, faGear, faSignOut } from '@fortawesome/free-solid-svg-icons';
import { useAppSharing } from "../context/Appsharing";
import AutomationIcon from "../assests/automation.png"


const MenuSection = () => {
    const { selectedMenu, setSelectedMenu, setIsRegistred, setIsLogined, setMsg, setActiveChat , setContactList, setChat, setAutomationMenu} = useAppSharing()

    const logoutFn = () => {
        document.cookie.split(";").forEach((cookie) => {
            const name = cookie.split("=")[0].trim();
            document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;`;
        });
        setIsRegistred(false)
        setIsLogined(false)
        setMsg([])
        setActiveChat(null)
        setContactList([])
        setChat([])
        setAutomationMenu(false)
        setSelectedMenu("home")
    }
    return (
        <MenuContainer>

            <MenuListDiv
                onClick={() => setSelectedMenu('home')}
            >
                <FontAwesomeIcon
                    icon={faHouse} style={{ color: 'white' }}

                />
            </MenuListDiv>

            <SettingDiv
                onClick={() => setSelectedMenu('automation')}
            >
                <img
                    src={AutomationIcon}
                    style={{ height: "30px", width: "30px", }}
                />

            </SettingDiv>

            <LogoutDiv
                title="Logout"

                onClick={logoutFn}
            >
                <FontAwesomeIcon
                    icon={faSignOut} style={{ color: 'white' }}

                />
            </LogoutDiv>

        </MenuContainer>
    )
}

export default MenuSection;