import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppSharing } from "../context/Appsharing";
import { BulkMsgChildWrapper, BulkMsgContainer, ContactSections, ContactSelectAll, ContactSendAll, SelectContact, SelectContactWrapper, TemplateDetails, TemplateDetailsContentWrapper, TemplateDetailsHeader } from "../style/Global.styled";
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from "react";



const BulkMsgSection = () => {
    const { setAutomationMenu, contactList } = useAppSharing()
    const [selectedContacts, setSelectedContacts] = useState([]);

    const handleCheckboxChange = (contact) => {
        setSelectedContacts(prev =>
            prev.includes(contact)
                ? prev.filter(selected => selected !== contact) // Unselect if already selected
                : [...prev, contact] // Select if not already selected
        );
    };


    const handleSelectAll = () => {
        setSelectedContacts(contactList)
    }

    const handleSendAll = () => {

    }


    return (
        <BulkMsgContainer>
            <BulkMsgChildWrapper>
                <FontAwesomeIcon
                    onClick={() => setAutomationMenu(false)}
                    icon={faTimes}
                    style={{
                        color: '#00a783', fontSize: "20px",
                        cursor: 'pointer',
                        position: "absolute",
                        top: "10px",
                        left: "10px",
                    }} />


                <TemplateDetails>
                    <TemplateDetailsHeader>
                        Template Details
                    </TemplateDetailsHeader>
                    <TemplateDetailsContentWrapper>
                        content
                    </TemplateDetailsContentWrapper>
                </TemplateDetails>

                <SelectContact>
                    <ContactSections>
                        Contact details
                    </ContactSections>
                    <SelectContactWrapper>
                        {contactList.map((contact, i) => (
                            <div className="contact-auto" key={i} style={{ display: "flex", alignItems: "center" }}>
                                <input
                                    type="checkbox"
                                    checked={selectedContacts.includes(contact)}
                                    onChange={() => handleCheckboxChange(contact)}
                                />
                                <span style={{ marginLeft: "8px" }}>{contact.sender}</span>
                            </div>
                        ))}

                        <ContactSelectAll onClick={handleSelectAll}>
                            Select all
                        </ContactSelectAll>
                        <ContactSendAll onClick={handleSendAll}>
                            Send Message
                        </ContactSendAll>
                    </SelectContactWrapper>
                </SelectContact>

            </BulkMsgChildWrapper>
        </BulkMsgContainer>
    )
}

export default BulkMsgSection;