



import React from 'react';
import { TemplateItem, TemplateTitle, TemplateStatus, TemplateListContainer } from '../style/Global.styled'; // Define these styled components
import { useAppSharing } from '../context/Appsharing';

const TemplateList = ({ templates }) => {
    const { setAutomationMenu, domainUrl } = useAppSharing()

    const sendTemplateFn = (id) => {
        console.log("template clicked",)
        let obj ={
            id: id,
        }

        fetch(`${domainUrl}/api/templates/sendtemplate`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            credentials: 'include',
            body: JSON.stringify(obj)
    
          })
          .then((e)=>e.json())
          .then((response)=>{
            console.log("response: ", response)
          })
    }

    return (
        <TemplateListContainer>
            {templates.map((template, index) => (
                <TemplateItem key={index}

                    // onClick={() => setAutomationMenu(true)}
                    onClick={() => sendTemplateFn(template.id)}


                >
                    <TemplateTitle>{template.name}</TemplateTitle>
                    <TemplateStatus>Status: {template.status}</TemplateStatus>
                    <div>Language: {template.language}</div>
                    <div>Category: {template.category}</div>
                    {template.components.map((component, i) => (
                        <div key={i}>
                            <strong>{component.type}:</strong> {component.text || component.url}
                        </div>
                    ))}
                </TemplateItem>
            ))}
        </TemplateListContainer>
    );
};

export default TemplateList;
