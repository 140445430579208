
import React, { createContext, useContext, useState } from "react";

const appSharingContext = createContext();

export const AppSharingProvider = ({ children }) => {

    const [isLoading, setIsLoading]= useState(true)
    const [appId, setAppId] = useState(2896466803828911);
    const [appSecret, setAppSecret] = useState('e7a7cbad12c92e4d72429dd99f1e3181')
    const [baseUrl, setBaseUrl] = useState("https://graph.facebook.com/v20.0/")
    const [domainUrl, setDomainUrl] = useState("https://wappbackend.affdeck.com")
    const [errMsg, setErrMsg] = useState(null)
    const [isRegistred, setIsRegistred] = useState(false)
    const [isLogined, setIsLogined] = useState(false)
    const [msg, setMsg] = useState([])
    const [activeChat, setActiveChat] = useState(null)
    const [contactList, setContactList] = useState([])
    const [chat, setChat] = useState([]);
    const [selectedMenu, setSelectedMenu] = useState("home")
    const [automationMenu, setAutomationMenu] = useState(false)


    const contextValue = {
        isLoading, 
        setIsLoading,
        baseUrl,
        setBaseUrl,
        domainUrl,
        setDomainUrl,
        errMsg,
        setErrMsg,
        appId,
        setAppId,
        appSecret,
        setAppSecret,
        isLogined,
        setIsLogined,
        isRegistred,
        setIsRegistred,
        msg,
        setMsg,
        activeChat,
        setActiveChat,
        contactList,
        setContactList,
        chat,
        setChat,
        selectedMenu, 
        setSelectedMenu,
        automationMenu, 
        setAutomationMenu

    };


    return (
        <appSharingContext.Provider value={contextValue}>
            {children}
        </appSharingContext.Provider>
    );
}


export const useAppSharing = () => {
    return useContext(appSharingContext);
};
