import { useEffect, useState } from "react";
import { BotLayer, ChatContainer, Container, LoginCont, TopLayer } from "../style/Global.styled";
import Chat from "./Chat";
import ChatList from "./Chatlist";
import MenuSection from "./MenuSection";
import { io } from "socket.io-client";
import { useAppSharing } from "../context/Appsharing";
import AutomationSection from "./AutomationSection";



const ChatSection = () => {

    const { domainUrl, setContactList, setMsg, setActiveChat, msg, activeChat, chat, setChat, selectedMenu, setSelectedMenu } = useAppSharing()



    useEffect(() => {
        // Fetch contact list from the server
        fetch(`${domainUrl}/api/message/getmessage`, {
            method: 'GET',
            credentials: 'include',
        })

            .then(e => e.json())
            .then((res) => {
                setActiveChat(res.contact[0].sender);
                setMsg(res.data);
                setContactList(res.contact);
            })
    }, [])


    useEffect(() => {

        let userChat = msg.filter(e =>
            e.sender === activeChat || e.recevier === activeChat
        );


        setChat(userChat);
    }, [msg, activeChat]);


    useEffect(() => {

        // Connect to Socket.io server
        const socket = io(domainUrl, {
            withCredentials: true
        });


        socket.on("allMsg", (msg) => {

            console.log("all msg", msg);
        })

        socket.on("newMessage", (newMsg) => {

            // setContactList
            setMsg((prevMsg) => {
                // Check if the message is already in the array
                const msgExists = prevMsg.some(
                    (msg) => msg.msg_id === newMsg.msgId
                );
                if (!msgExists) {
                    return [...prevMsg, {
                        waba_id: newMsg.update.wabaId,
                        sender: newMsg.update.sender,
                        msg_id: newMsg.update.msgId,
                        msg_body: newMsg.update.msgBody,
                        sent_time: newMsg.update.sentTime,
                        msg_type: newMsg.update.msgType,
                        display_number: newMsg.update.displayNumber,
                        phone_number_id: newMsg.update.phnId,
                        recevier: newMsg.update.recevier,
                        status: newMsg.update.status,
                        reaction: newMsg.update.reaction
                    }];
                }
                return prevMsg;
            });
        });


        socket.on("newStatus", (newStatus) => {
            const [msg_id, status] = newStatus.update;

            // Update the message status in the state
            setMsg((prevMsgs) =>
                prevMsgs.map((message) =>
                    message.msg_id === msg_id
                        ? { ...message, status: status }
                        : message
                )
            );
        })



        socket.on("newReaction", (newReaction) => {
            const [msg_id, reaction] = newReaction.update;

            // Update the message status in the state
            setMsg((prevMsgs) =>
                prevMsgs.map((message) =>
                    message.msg_id === msg_id
                        ? { ...message, reaction: reaction }
                        : message
                )
            );
        })


        socket.on("newImage", (newImage) => {
        })

        // Cleanup socket connection on unmount
        return () => {
            socket.disconnect();
        };
    }, []);


    return (
        <ChatContainer>
            <TopLayer />
            <BotLayer />

            <Container>
                <MenuSection />
                {selectedMenu === "home" &&
                    <>
                        <ChatList />
                        <Chat />
                    </>
                }

                {
                   selectedMenu === "automation" &&
                   
                   <AutomationSection/>
                }


            </Container>

        </ChatContainer>
    )
}

export default ChatSection;