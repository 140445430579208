import { useEffect, useState } from "react";
import { useAppSharing } from "../context/Appsharing";
import { AutomationContainer } from "../style/Global.styled";
import BulkMsgSection from "./BulkMsgSection";
import TemplateList from "./TemplatList";




const AutomationSection = () => {

    const { automationMenu, domainUrl } = useAppSharing()

    const [templateData, setTemplateData]= useState([])
    // const [id, setId]= useState("")
    useEffect(() => {
        // Fetch contact list from the server
        fetch(`${domainUrl}/api/templates/gettemplate`, {
            method: 'GET',
            credentials: 'include',
        })

            .then(e => e.json())
            .then((res) => {
                console.log("ressed", res)
                setTemplateData(res.templates)
                // setId(res.phoneId)
            })
    }, [])

    // const templateData = [
    //     {
    //         "name": "welcome_msggg",
    //         "parameter_format": "POSITIONAL",
    //         "components": [
    //             {
    //                 "type": "HEADER",
    //                 "format": "TEXT",
    //                 "text": "Hi {{1}}",
    //                 "example": {
    //                     "header_text": [
    //                         "khaleel"
    //                     ]
    //                 }
    //             },
    //             {
    //                 "type": "BODY",
    //                 "text": "Hey weclome to {{1}}",
    //                 "example": {
    //                     "body_text": [
    //                         [
    //                             "flonnect"
    //                         ]
    //                     ]
    //                 }
    //             },
    //             {
    //                 "type": "FOOTER",
    //                 "text": "regards"
    //             }
    //         ],
    //         "language": "en",
    //         "status": "APPROVED",
    //         "category": "MARKETING",
    //         "sub_category": "CUSTOM",
    //         "id": "513980488224777"
    //     },
    //     {
    //         "name": "welcome_msg",
    //         "parameter_format": "POSITIONAL",
    //         "components": [
    //             {
    //                 "type": "HEADER",
    //                 "format": "TEXT",
    //                 "text": "header message"
    //             },
    //             {
    //                 "type": "BODY",
    //                 "text": "body msg here goes"
    //             },
    //             {
    //                 "type": "FOOTER",
    //                 "text": "footer"
    //             }
    //         ],
    //         "language": "en",
    //         "status": "APPROVED",
    //         "category": "MARKETING",
    //         "sub_category": "CUSTOM",
    //         "id": "585263804036776"
    //     },
    //     {
    //         "name": "welcomemsg",
    //         "parameter_format": "POSITIONAL",
    //         "components": [
    //             {
    //                 "type": "HEADER",
    //                 "format": "TEXT",
    //                 "text": "Statement available"
    //             },
    //             {
    //                 "type": "BODY",
    //                 "text": "This is to notify you that your latest statement for your {{1}} account is now available. \n\nPlease log into your account to view your statement.",
    //                 "example": {
    //                     "body_text": [
    //                         [
    //                             "Mankt checking plus account"
    //                         ]
    //                     ]
    //                 }
    //             },
    //             {
    //                 "type": "BUTTONS",
    //                 "buttons": [
    //                     {
    //                         "type": "URL",
    //                         "text": "View statement",
    //                         "url": "https://flonnect.com/"
    //                     }
    //                 ]
    //             }
    //         ],
    //         "language": "en_US",
    //         "status": "PENDING",
    //         "category": "UTILITY",
    //         "library_template_name": "statement_available_2",
    //         "id": "961429172486112"
    //     }
    // ];

    return (
        <AutomationContainer>
            <TemplateList templates={templateData} >
                template list
            </TemplateList>

            {
                automationMenu &&
                <BulkMsgSection />
            }
        </AutomationContainer>
    )
}

export default AutomationSection;