import { useEffect, useState } from "react";
import { BotLayer, ErrorBox, LoginBtn, LoginCont, LogInContainer, TopLayer } from "../style/Global.styled";
import { useAppSharing } from "../context/Appsharing";
import { LoginHandle } from "../utils/LoginHandle";

const LoginSection = () => {

    const { appId, errMsg, domainUrl, setIsRegistred, setIsLogined } = useAppSharing()


    useEffect(() => {

        // Initialize the Facebook SDK
        window.fbAsyncInit = function () {
            window.FB.init({
                appId: appId,
                cookie: true,
                autoLogAppEvents: true,
                xfbml: true,
                version: "v20.0",
            });
        };

        // Load the SDK asynchronously
        (function (d, s, id) {
            var js,
                fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s);
            js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            js.async = true;
            js.defer = true;
            js.crossOrigin = "anonymous";
            fjs.parentNode.insertBefore(js, fjs);
        })(document, "script", "facebook-jssdk");
    }, []);

    const launchWhatsAppSignup = () => {

        window.FB.login(
            function (response) {
                if (response?.authResponse && response?.authResponse?.code) {
                    LoginHandle(response?.authResponse?.code, domainUrl, setIsRegistred, setIsLogined)
                }

            },
            {
                config_id: "781140977522289", // Use your own config ID
                response_type: "code",
                override_default_response_type: true,
                extras: {
                    setup: {},
                },
            }
        );
    };


    return (
        <LogInContainer>
            <TopLayer />
            <BotLayer />

            <LoginCont>
                <LoginBtn onClick={launchWhatsAppSignup}>
                    Login with Facebook
                </LoginBtn>
                <ErrorBox>{errMsg}</ErrorBox>
            </LoginCont>

        </LogInContainer>
    )
}

export default LoginSection;
