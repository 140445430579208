import { BotLayer, LoaderContainer, LoginCont, TopLayer } from "../style/Global.styled";

const LoaderComponent = () => {
    return (
        <LoaderContainer >
            <TopLayer />
            <BotLayer />
            <LoginCont>

            </LoginCont>

        </LoaderContainer>
    )
}

export default LoaderComponent;