import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChatListBox, ChatListCont, ChatListContainer, ChatListDiv, ChatListFloatingmsg, ChatListHeader, ChatListImg, ChatListMsg, ChatListName, ChatListSearch, ChatListSearchInput, ChatListTopSection, NoContactList } from "../style/Global.styled";
import { faMagnifyingGlass, faUser } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from "react";
import { useAppSharing } from "../context/Appsharing";
import { TimeFormatter } from "../utils/TimeFormatter";


const ChatList = () => {
    const { contactList, msg, activeChat, setContactList, setActiveChat } = useAppSharing();


    const [searchActive, setSearchActive] = useState(false)






    useEffect(() => {
        if (msg.length === 0) return;

        const display_number = msg[0].display_number;

        const sortedMessages = [...msg].sort((a, b) => b.sent_time - a.sent_time);

        const uniqueContactsMap = new Map();
        sortedMessages.forEach((message) => {
            const otherParty = message.sender === display_number ? message.recevier : message.sender;

            if (otherParty !== display_number && !uniqueContactsMap.has(otherParty)) {
                uniqueContactsMap.set(otherParty, message);
            }
        });

        const updatedContactList = Array.from(uniqueContactsMap.values()).map(message => ({
            sender: message.sender === display_number ? message.recevier : message.sender,
            latestMessage: message.msg_body,
            time: TimeFormatter(message.sent_time),
        }));

        setContactList(updatedContactList);

    }, [msg]);


    const msgPreview = (num, val) => {

        let sortedMessages = msg.sort((a, b) => b.sent_time - a.sent_time);

        let tempArr = sortedMessages.filter((f) => {
            return f.recevier === num || f.sender === num;
        });


        if (val == "msg") {
            return tempArr.length > 0 ? tempArr[0].msg_body : null;
        } else {
            let timeVal = TimeFormatter(tempArr[0].sent_time)
            return timeVal
        }

    };


    return (
        <ChatListContainer>
            <ChatListTopSection>
                {
                    searchActive ?
                        <ChatListSearchInput
                            type="text"
                            placeholder="Search contacts"
                            autoFocus
                            onBlur={() => setSearchActive(false)}

                        />
                        :
                        <ChatListSearch onClick={() => setSearchActive(true)}>
                            <FontAwesomeIcon icon={faMagnifyingGlass} style={{ color: 'white' }} />

                        </ChatListSearch>
                }

            </ChatListTopSection>
            <ChatListHeader>
                Messages
            </ChatListHeader>



            <ChatListCont>

                {contactList.map((e, i) => {
                    return (
                        <ChatListDiv
                            key={i}
                            onClick={() => setActiveChat(e.sender)}
                            val={e.sender === activeChat ? true : false}

                        >
                            <ChatListImg>

                                <FontAwesomeIcon icon={faUser} style={{ color: 'white', height: "25px" }} />

                            </ChatListImg>
                            <ChatListBox>
                                <ChatListName>
                                    {e.sender}
                                </ChatListName>
                                <ChatListMsg>
                                    {
                                        msgPreview(e.sender, "msg")
                                    }
                                </ChatListMsg>

                            </ChatListBox>

                            <ChatListFloatingmsg>
                                {
                                    msgPreview(e.sender, "time")
                                }
                            </ChatListFloatingmsg>

                        </ChatListDiv>
                    )
                })}
                {
                    contactList.length === 0 &&
                    <NoContactList>
                        No contacts found
                    </NoContactList>
                }
            </ChatListCont>

        </ChatListContainer>
    )
}

export default ChatList;