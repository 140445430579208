

export const LoginStatus = (domainUrl, setIsRegistred, setIsLogined, setIsLoading) => {
    fetch(`${domainUrl}/api/status`, {
        credentials: 'include'

    }).then((e) => e.json())
        .then((res) => {
            setIsLoading(false)
            let {isToken,isRegistration}= res

            if(isToken && !isRegistration){
                setIsRegistred(false)
            }
            if(isToken && isRegistration){
                setIsRegistred(true)
                setIsLogined(true)
            }

        })
}



