import { useAppSharing } from "../context/Appsharing";
import { BotLayer, LoginCont, RegisterBtn, RegistrationContainer, TopLayer } from "../style/Global.styled";



const RegistrationSection = () => {
    const { setIsRegistred, domainUrl, setIsLogined } = useAppSharing()

    const registerAcnt = () => {

        fetch(`${domainUrl}/api/register`, {

            credentials: 'include'

        })
            .then((res) => res.json())
            .then((data) => {
                if (data.message === 'Token not found') {
                    setIsLogined(false)
                    return
                }

                if (data.isRegistered) {
                    setIsRegistred(true)
                    setIsLogined(true)
                }
            })
            .catch((err) => {
                console.log("log", err)
            })


    }
    return (
        <RegistrationContainer>
            <TopLayer></TopLayer>
            <BotLayer></BotLayer>
            <LoginCont>
                <RegisterBtn onClick={registerAcnt}>

                    Register as cloud whatsapp
                </RegisterBtn>
            </LoginCont>
        </RegistrationContainer>
    )
}

export default RegistrationSection;