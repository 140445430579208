import styled from "styled-components";
import { createGlobalStyle } from "styled-components";


export const GlobalStyles = createGlobalStyle`
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box; /* Helps with box sizing */
  }
`

export const MainContainer = styled.div`
width: 100vw;
height: 100vh;
background-color:  #090F13;
min-height: 742px;
`

export const LogInContainer = styled.div`
width: 100%;
height: 100%;
display: flex; 
justify-content: center;
align-items: center;
display: flex;
flex-direction: column;
position: relative;
`

export const Container = styled.div`
position: absolute;
    z-index: 2;
    display: flex;
    flex: none;
    width: 90%;
    height: 90%;
    margin-right: auto;
    margin-left: auto;
    overflow: hidden;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 17px 50px 0 rgba(11, 20, 26, .19), 0 12px 15px 0 rgba(11, 20, 26, .24);
    align-items: center;
    justify-content: center;;
`

export const TopLayer = styled.div`
width: 100%;
height: 20%;
background-color: #00a884;
`

export const BotLayer = styled.div`
width: 100%;
height: 80%;
background-color: #d8dbdc;
`
export const LoginCont = styled.div`
position: absolute;
    z-index: 2;
    display: flex;
    flex: none;
    flex-direction: column;
    width: 90%;
    height: 90%;
    margin-right: auto;
    margin-left: auto;
    overflow: hidden;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 17px 50px 0 rgba(11, 20, 26, .19), 0 12px 15px 0 rgba(11, 20, 26, .24);
    align-items: center;
    justify-content: center;
`

export const RegistrationContainer = styled.div`
width: 100%;
height: 100%;
display: flex;
justify-content: center;
align-items: center;
display: flex;
flex-direction: column;
position: relative;

`
export const ChatContainer = styled.div`
width: 100%;
height: 100%;
display: flex;
flex-direction: column;

justify-content: center;
align-items: center;
display: flex;
position: relative;
`

export const LoaderContainer = styled.div`
width: 100%;
height: 100%;
display: flex;
justify-content: center;
align-items: center;
display: flex;
flex-direction: column;
position: relative;
`

export const NavbarCont = styled.div`
width: 100%;
background-color: #343a40;
height: 50px;
color: #fff;
`

export const LoginBtn = styled.div`
background-color: #1877f2;
border: 0;
border-radius: 4px;
color: #fff;
cursor: pointer;
font-family: Helvetica, Arial, sans-serif;
font-size: 16px;
font-weight: bold;
height: 40px;
padding: 10px 24px;
user-select: none;
`

export const ErrorBox = styled.div`
color: #cd1717;
font-size: 15px;
font-weight: 700;
margin-top: 10px;
`


export const RegisterBtn = styled.div`
background-color: #1877f2;
border: 0;
border-radius: 4px;
color: #fff;
cursor: pointer;
font-family: Helvetica, Arial, sans-serif;
font-size: 16px;
font-weight: bold;
height: 40px;
padding: 10px 24px;
user-select: none;
`

export const LeftChatContainer = styled.div`
width: 30%;
height: 100%;
background-color: #102320;

`

export const RightChatContainer = styled.div`
width: 70%;
height: 100%;
background-color: #102320;
`

export const ContactButton = styled.div`
width: 100%;
height: 40px;
background-color: gainsboro;
border: 1px solid #000;
border-collapse: collapse;
cursor: pointer;

&:hover{
  background-color: lightgray;
}
`


export const ChatHeader = styled.div`
width: 100%;
  height: 50px;
  font-size: 30px;
  background-color: #102320e0;
  color: #fff;
  font-weight: 600;
  display: flex;
  justify-content:  center;
  align-items: center;
  flex-direction: column;
`

export const ChatBody = styled.div`
    width: 100%;
    height: calc(100% - 70px);
    background-color: #102320;
    position: relative;
    box-shadow: inset 14px -3px 20px 4px #1e3d38;
    display: flex; /* Use flex to stack items */
    flex-direction: column; /* Stack messages vertically */
    padding: 10px; /* Optional padding */
`;
export const ChatFooter = styled.div`
 position: absolute;
 bottom: 0px;
 width: 97%;
 height: 50px;
 background-color:  #233938;
 display: flex;
 justify-content: space-between;
`

export const ChatInput = styled.textarea`
width: 90%;
height: 50px;
resize:none;
display: flex;

`

export const Sendbtn = styled.div`
width: 10%;
height: 50px;
background-color: #29897c;
cursor: pointer;
display: flex;
justify-content: center;
align-items: center;
font-weight: 600;
`
export const ChatBoxWrapper = styled.div`
    align-self: ${props => props.val ? "flex-start" : "flex-end"};
    margin-left: ${props => props.val ? "0" : "auto"};
    margin-right: ${props => props.val ? "auto" : "0"};
    width: fit-content; 
    max-width: 70%; 
    margin-bottom: 14px;

    #status{
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 6px;
    color: #8b8686;
    font-weight: 500;
    height: 14px;
    }

    img{
      height: 100%;
    }

    #recevied-time{
      font-weight: 500;
    height: 14px;
    color: #8b8686;
    font-size: 10px;

    }

`
export const ChatBox = styled.div`
    padding: 10px 15px; /* Added padding for better spacing */
    background-color: #dad9d9;
    border-radius: 8px;

    /* align-self: ${props => props.val ? "flex-start" : "flex-end"};
    margin-left: ${props => props.val ? "0" : "auto"};
    margin-right: ${props => props.val ? "auto" : "0"}; */

    position: relative;

    span{
      position: absolute;
      left: -27px;
    }
    
    `;

export const ChatBoxStatus = styled.div`

`
export const ChatsCont = styled.div`
width: 100%;
height: 100%;
overflow-y: auto; 
 padding: 100px 10px 60px 10px;
 /* background: ${(props) => props.bg ? `url(${props.bg})` : ""}; */
`


// new Ui style starts here


export const MenuContainer = styled.div`
flex-basis: 4%;
min-width: 55px;

height: 100%;
background-color: #128c7e;
padding: 75px 7px 12px 7px;
display: flex;
flex-direction: column;
gap: 10px;
position: relative;
`
export const ChatListContainer = styled.div`
flex-basis: 16%;
min-width: 280px;
height: 100%;
background-color: #fff;
gap: 2px;
`

export const ChatBoxContainer = styled.div`
flex-basis: 80%;
height: 100%;
background-color: #eeeeee;
position: relative;
`

export const MenuListDiv = styled.div`
width: 100%;
aspect-ratio: 1 / 1;
border-radius: 7px;
font-size: 25px;
display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover{
      background-color: #0a564e;

    }

img{
  width: 100%;
  height: auto;
}
`

export const SettingDiv = styled.div`
width: 100%;
aspect-ratio: 1 / 1;
border-radius: 7px;
font-size: 25px;
display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover{
      background-color: #0a564e;

    }

img{
  width: 100%;
  height: auto;
}
`
export const LogoutDiv = styled.div`
width: calc(100% - 14px);
    aspect-ratio: 1 / 1;
    border-radius: 7px;
    font-size: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
    bottom: 7px;


    &:hover{
      background-color: #0a564e;

    }

img{
  width: 100%;
  height: auto;
}
`


export const ChatListTopSection = styled.div`
width: 100%;
height: 57px;
display: flex;
justify-content: flex-end;
align-items: center;
padding: 0px 5px;
`



// chat list section style 
export const ChatListSearch = styled.div`
height: 70%;
aspect-ratio: 1 / 1;
border-radius: 23px;
display: flex;
justify-content: center;
align-items: center;
cursor: pointer;
background-color: #a6a6a6;


`

export const ChatListSearchInput = styled.input`
width: 100%;
height: 70%;
padding: 5px;
border-radius: 7px;
border: none;
outline: none;
background: #fff;
box-shadow: none;
`

export const ChatListHeader = styled.div`
font-size: 20px;
font-weight: 600;
padding: 5px;
`

export const ChatListCont = styled.div`
width: 100%;
height: 88%;
overflow-y: auto;
gap: 5px;
display: flex;
flex-direction: column;

::-webkit-scrollbar {
    width: 2px; /* Width of the scrollbar */
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1; /* Background of the scrollbar track */
    border-radius: 10px; /* Rounded corners */
  }

  ::-webkit-scrollbar-thumb {
    background-color: red; /* Color of the scrollbar thumb */
    border-radius: 10px; /* Rounded corners */
    background-clip: padding-box; /* Clip thumb for rounded corners */
    border-radius: 10px;
  }


  scrollbar-width: none;
  -ms-overflow-style: none; 
  
  &::-webkit-scrollbar {
    display: none; 
  }


  /* Scrollbar styles for Firefox */
  scrollbar-width: thin; 
  scrollbar-color: #888 #fff; /* Thumb color and track color */
`

export const ChatListDiv = styled.div`
width: 100%;
height: 50px;
display: flex;
padding: 0px 5px;
align-items: center;
position: relative;
cursor: pointer;
background: ${(props) => props.val ? 'linear-gradient(to right, #128c7e, #128c7e)' : ''};
color: ${(props) => props.val ? "#fff" : '#000'};

&:hover{
  background: linear-gradient(to right, #128c7e, #fff); 
}

`

export const NoContactList=styled.div`
width: 100%;
height: 100%;
display: flex;
justify-content: center;
align-items: center;
`
export const ChatListImg = styled.div`
height: 80%;
aspect-ratio: 1 / 1;
background-color: #d4d4d4;
display: flex;
justify-content: center;
align-items: center;
border-radius: 27px;


`

export const ChatListBox = styled.div`
height: 100%;
width: 100%;
aspect-ratio: 1 / 1;
display: flex;
flex-direction: column;
justify-content: center;
cursor: pointer;
border-bottom:2px solid #878787;

`

export const ChatListName = styled.div`
font-size: 15px;
font-weight: 600;
padding: 0px 5px;
`

export const ChatListMsg = styled.div`
  width: 100%;
  height: 18px;
  font-size: 12px;
  padding: 0px 5px;
  color: #adaeac;

  box-sizing: border-box;
  overflow: hidden;

`;


export const ChatListFloatingmsg = styled.div`
height: 100%;
position: absolute;
right: 5px;
font-size: 10px;
color: #adaeac;
margin-top: 15px;
`


export const ChatWrapper = styled.div`
width: 100%;
height: 90%;
background-color: #eeeeee;
position: relative;
`

export const ChatInputWrapper = styled.div`
width: 100%;
height: 10%;
background-color: #fff;
position: absolute;
display: flex;
align-items: center;
min-height:68px;
`


export const ChatInputCont = styled.div`
width: 100%;
height: 50px;
position: relative;
/* background-color: rebeccapurple; */
display: flex;
/* justify-content: center; */
align-items: center;

`

export const ChatInputArea = styled.textarea`
width: 90%;
height: 100%;
min-height: 50px;
padding: 10px 50px;
resize:none;
border-radius: 28px;
outline: none;
border: 3px solid #eeeeee;
display: flex;
  align-items: center;
  line-height: 1.5;
  font-size: 15px;
  margin-left: 2%;
  scrollbar-width: none;
  -ms-overflow-style: none;
  
  &::-webkit-scrollbar {
    display: none; 
  }




&:focus-visible{
  outline: none;
  height: 200px;
  position: absolute;
  bottom: 0px;
  padding: 10px 15px;

}
`

export const ChatSendbtn = styled.div`
width: 5%;
height: 100%;
aspect-ratio: 1/1;
display: flex;
align-items: center;
justify-content: center;
padding: 4px;
position: absolute;
right: 20px;


cursor: pointer;
/* background-color: #29897c; */
img{
  height: 100%;
}
`

export const Attachment = styled.div`
/* width: 5%; */
aspect-ratio: 1/1;
background-color: #a6a6a6;
position: absolute;
    left: 2.8%;
    height: 35px;
    border-radius: 20px;
display: flex;
justify-content: center;
align-items: center;
cursor: pointer;
z-index: 2;
`


export const EmojiCont = styled.div`
aspect-ratio: 1/1;
position: absolute;
    left: 89%;
    height: 35px;
    border-radius: 20px;
display: flex;
justify-content: center;
align-items: center;
font-size: 32px;
cursor: pointer;
z-index: 2;

`

export const ChatTitleCont = styled.div`
width: 70%;
height: 50px;
background-color: #fff;
left: 0;
  right: 0;
  top: 35px;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 27px;
  font-weight: 600;
`


export const AutomationContainer = styled.div`
flex-basis: 96%;
height: 100%;
position: relative;

`


export const TemplateListContainer = styled.div`
/* flex-basis: 4; */
height: 100%;
display: flex;
    gap: 25px;
    padding: 25px;
    overflow-y: auto;
    flex-wrap: wrap;
    margin: auto;
    justify-content: flex-start;
`



export const TemplateItem = styled.div`
width: fit-content;
max-width: 200px;
max-height: 300px;
    padding: 10px;
    background-color: #00a7831f;
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgb(43 43 43);
    cursor: pointer;
    box-sizing: initial;
`;

export const TemplateTitle = styled.h3`
    font-size: 18px;
    font-weight: 600;
    color: #128c7e;
`;

export const TemplateStatus = styled.p`
    font-size: 14px;
    color: ${props => props.status === "APPROVED" ? "green" : "orange"};
`;


export const BulkMsgContainer = styled.div`
    background-color: #eeeeee;
    height: 94%;
    width: 70%;
    position: absolute;
    right: 0px;
    top: 3%;
    border-radius: 10px 0px 0px 10px;
`

export const BulkMsgChildWrapper = styled.div`
width: 100%;
height: 100%;
border-radius: 10px 0px 0px 10px;
display: flex;
position: relative;
`


export const TemplateDetails = styled.div`
width: 50%;
height: 100%;
background-color: #87f3e714;
`

export const SelectContact = styled.div`
width: 50%;
height: 100%;
/* background-color: green; */
`

export const TemplateDetailsHeader = styled.div`
    padding-top: 45px;
    font-size: 25px;
    font-weight: 600;
    text-align: center;
    color: #128c7e;
    border-bottom: 2px solid;
`


export const ContactSections = styled.div`
    padding-top: 45px;
    font-size: 25px;
    font-weight: 600;
    text-align: center;
    color: #128c7e;
    border-bottom: 2px solid;
`

export const TemplateDetailsContentWrapper = styled.div`
width: 100%;
height: calc(100% - 86px);
background-color: red;
`

export const SelectContactWrapper = styled.div`
width: 100%;
height: calc(100% - 86px);
padding: 10px;
overflow-y: auto;
position: relative;

.contact-auto{
  cursor: pointer;
  margin-bottom: 5px;
}
`

export const ContactSelectAll = styled.div`
position: absolute;
right: 20px;
top: 10px;
background-color: #128c7e;
padding: 5px 10px;
border-radius: 4px;
color: #fff;
cursor: pointer;
`



export const ContactSendAll = styled.div`
position: absolute;
right: 20px;
bottom: 10px;
background-color: #128c7e;
padding: 5px 10px;
border-radius: 4px;
color: #fff;
cursor: pointer;
`