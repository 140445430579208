


export const LoginHandle = (code, url, setIsRegistred, setIsLogined) => {

    fetch(`${url}/api/login`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ code }),
    })
        .then((res) => res.json())
        .then((data) => {
            if (data.error) return
            setIsLogined(true)
            if (data.registration) {
                setIsRegistred(true)

            } else {
                setIsRegistred(false)
            }
        })
        .catch((err) => {
            console.log("log", err)
            setIsRegistred(false)
        })

}