import { Attachment, ChatBox, ChatBoxContainer, ChatBoxWrapper, ChatInputArea, ChatInputCont, ChatInputWrapper, ChatsCont, ChatSendbtn, ChatTitleCont, ChatWrapper, EmojiCont } from "../style/Global.styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip, faSmile, faCheck } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import sendBtn from "../assests/sendBtn.png"
import { useEffect, useRef, useState } from "react";
import { useAppSharing } from "../context/Appsharing";

import sentIcon from "../assests/sent.png"
import deliveredIcon from "../assests/delivered.png"
// import bg from "../assests/bg.jpg"
import readIcon from "../assests/read.png"
import { TimeFormatter } from "../utils/TimeFormatter";



const Chat = () => {
  const { activeChat, chat, domainUrl } = useAppSharing()
  const [inputFocused, setInputFocused] = useState(false)
  const [sortedChat, setSortedChat] = useState([])
  const inputRef = useRef(null)
  const [msgText, setMsgText] = useState("");
  const chatContainerRef = useRef(null);

  useEffect(() => {
    let sortedMsg = chat.sort((a, b) => parseInt(a.sent_time) - parseInt(b.sent_time));
    setSortedChat(sortedMsg)
  }, [chat])


  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [sortedChat])

  const sendMsg = () => {



    if (msgText) {
      if (!activeChat) {
        return
      }

      let userObj = {
        msg: msgText,
        time: Math.round(Date.now() / 1000),
        recevier: activeChat
      };
      fetch(`${domainUrl}/api/message/sendmessage`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        credentials: 'include',
        body: JSON.stringify(userObj)

      })
        .then((e) => e.json())
        .then((data) => {
          if (data.response.status == 400) {

            toast.error('Message failed', {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              transition: Bounce,
            });
          }

        })

      setMsgText("")
      inputRef.current.value = ""
    }
  }

  return (
    <ChatBoxContainer>
      <ChatWrapper>
        <ChatTitleCont>
          {activeChat}
        </ChatTitleCont>


        <ChatsCont ref={chatContainerRef}
        // bg={bg}
        >


          {sortedChat.map(message => (
            <ChatBoxWrapper
              key={message.msg_id}
              val={message.sender == activeChat ? true : false}
            >
              <ChatBox
              //  key={message.msg_id} val={message.sender == activeChat ? true : false}
              >
                {message.msg_body}
                <span>{message.reaction}</span>
              </ChatBox>
              {
                message.sender == message.display_number ?
                  <div id="status">
                    {
                      TimeFormatter(message.sent_time)
                    }
                    {
                      <img src={
                        message.status == "read" ? readIcon :
                          message.status == "delivered" ? deliveredIcon : message.status == "sent" && sentIcon
                        // deliveredIcon
                      }
                        style={{
                          height: message.status === "sent" ? "78%" : "100%"
                        }}
                      />

                    }

                  </div> :
                  <div id="recevied-time">

                    {
                      TimeFormatter(message.sent_time)

                    }
                  </div>
              }


            </ChatBoxWrapper>
          ))}

        </ChatsCont>

      </ChatWrapper>
      <ChatInputWrapper>
        <ChatInputCont>
          <Attachment>
            <FontAwesomeIcon icon={faPaperclip} style={{ color: 'white' }} />
          </Attachment>

          <ChatInputArea
            type="text"
            placeholder="Type your message"
            ref={inputRef}
            onChange={(e) => setMsgText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                sendMsg();
                inputRef.current.blur();
              }
            }}
          />
          <ChatSendbtn onClick={sendMsg}>
            <img src={sendBtn} />
          </ChatSendbtn>

          <EmojiCont>
            <FontAwesomeIcon icon={faSmile} style={{ color: '#a6a6a6' }} />

          </EmojiCont>

        </ChatInputCont>

      </ChatInputWrapper>

      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </ChatBoxContainer>
  )
}

export default Chat;