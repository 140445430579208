import { useEffect } from "react";
import { useAppSharing } from "./context/Appsharing";
import { GlobalStyles, MainContainer } from "./style/Global.styled";
import LoginSection from "./component/LoginSection";
import RegistrationSection from "./component/RegistrationSection";
import ChatSection from "./component/ChatSection";
import { LoginStatus } from "./utils/LoginStatus";
import LoaderComponent from "./component/LoaderComponent";

function App() {
  // Load Facebook SDK on component mount
  const { isRegistred, isLogined, domainUrl, setIsRegistred, setIsLogined, isLoading, setIsLoading } = useAppSharing()

  useEffect(() => {

    LoginStatus(domainUrl, setIsRegistred, setIsLogined, setIsLoading)


    // Initialize the Facebook SDK
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: "2896466803828911",
        cookie: true,
        autoLogAppEvents: true,
        xfbml: true,
        version: "v20.0",
      });
    };

    // Load the SDK asynchronously
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      js.async = true;
      js.defer = true;
      js.crossOrigin = "anonymous";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");



  }, []);

  return (

    <>
      <GlobalStyles />
      <MainContainer>
        {/* <Navbar /> */}


        {
          isLoading ? <LoaderComponent /> : isLogined ? (
            isRegistred ? (
              <ChatSection />
            ) : (
              <RegistrationSection />
            )
          ) : (
            <LoginSection />
          )
        }

        { }


      </MainContainer>
    </>

  );
}

export default App;
